.navlogo {
  padding: 5px;
  max-height: 90px;
}

.navi {
  border-bottom: solid #495057 1px;
  min-width: 230px;
  z-index: 100000;
}
