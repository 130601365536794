@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body{
  margin: 0;
  padding:  0;
  box-sizing: border-box;
  font-family: 'Fira Code', monospace;
  color: #fff;
  background-color: #111;
}

a {
  text-decoration: none !important;
}


.page-content {
  min-height: 75vh;
  padding: 0;
  padding-top: 99px;
  overflow-x: hidden;
}


section {
  display: grid;
  place-items: center;
  align-content: center;
  min-height: 100vh;
  justify-content: center;
}

.about-intro {
  display: flex;
  font-size: 25pt;
  padding-right: 25px;
  flex-direction: column;
  width: fit-content;
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.sec-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.img-examples {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.fbs-logo {
  min-height: 250px;
  max-height: 350px;
  min-width: 225px;
}

.examp-img:nth-child(2) {
  transition-delay: 200ms;
}

.examp-img:nth-child(3) {
  transition-delay: 400ms;
}

.examp-img:nth-child(4) {
  transition-delay: 600ms;
}

.company-intro {
  background-color: gainsboro;
  color: #0c0c0c;
}

.company-services {
  padding: 200px 0px;
  background-color: whitesmoke;
  color: #0c0c0c;
}

.company-builds {
  background-color: gainsboro;
  color: #0c0c0c;
}

.company-webdev {
  background-color: whitesmoke;
  color: #0c0c0c;
}
.company-outro {
  background-color: gainsboro;
  color: #0c0c0c;
}

.build-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;
}

.bp-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 50px 0;
}

.cust-srvc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.lrn-more-btn {
  background-color: #111 !important;
}

.lrn-more-btn:hover {
  background-color: #555555 !important;
}

#scroll-to-top {
  background-color: #ABB2BF;
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 7px;
  width: 30px;
  height: 30px;
  border: none;
  box-shadow: none;
}

#arrow {
  padding-left: 6px;
  color: #282C33;
}

.footy-logo {
  max-height: 100px;
}

.footy {
  background-color: #111;
  min-height: 20vh;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #111;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}

@media (min-width: 500px) {
  .fbs-logo {
    height: 350px;
  }
 }


 @media (max-width: 500px) {
  .about-intro {
    display: flex;
    font-size: 20pt;
    padding: 0 25px;
    flex-direction: column;
    width: fit-content;
  }
 }

 @media (max-width: 800px) {
 
  .card-img-top {
    width: 100%;
    height: 60%;
    object-fit: cover;
  }
 }